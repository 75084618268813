<template>
  <div class="error-page-main pt-30">
    <div class="container">
      <div class="error-page">
        <ClientOnly>
          <cmsBlockSimple
            v-if="typeof pageNotFound == 'object'"
            :identifier="pageNotFound.identifier"
          />
        </ClientOnly>
        <!-- <div class="devider desktop-show"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import errorImg from "@/esf_utrecht_bankxl/assets/images/errorimg.png";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
import config from "@config";
export default {
  name: "PageNotFound",
  components: {
    ClientOnly,
    cmsBlockSimple,
  },
  data() {
    return {
      errorImg,
    };
  },
  computed: {
    pageNotFound() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "page_not_found"
      );
    },
  },
  metaInfo() {
    return {
      title: this.$t("page_not_found"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
